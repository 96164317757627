import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuthentication } from "@providers/AuthenticationProvider";

function Login() {
  const router = useRouter();
  const { organization, invitation } = router.query;
  const { loginWithRedirect } = useAuthentication();
  useEffect(() => {
    if (typeof organization === "string" && typeof invitation === "string") {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin + "/handleRedirect",
          organization: organization,
          invitation: invitation,
        },
      });
    }
  }, []);

  return <></>;
}

export default Login;
